<template>
  <div class="registered">
    <div class="main">
      <div class="logo"></div>
      <div class="logo_title">电子邮箱登陆</div>
      <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
      <div class="from_Email">
        <input v-model="from.Email">
        <div class="title">电子邮箱</div>
      </div>
      <div class="from_Password">
        <input v-model="from.password" type="password">
        <div class="title">密码</div>
      </div>
      <button @click="In_open">登陆</button>
      <div class="footer">
        <div class="scan_code">扫码登陆</div>
        <div class="in_code" @click="Up_open">立即注册</div>
        <div class="code_c">扫码功能暂未开通</div>
        <!--        <div class="scan_account">账户密码登陆</div>-->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/pc/Footer";

export default {
  data() {
    return {
      region: '',
      region_style: 0,
      remind:'',
      remind_text:'',
      from: {
        Email: '',
        Password:''
      }
    }
  },
  mounted() {

  },
  methods: {
    close(){
      this.from.Mail='';
      this.from.Password='';
    },
    remind_success(text) {
      this.remind = 'height: 20px;background-color: rgba(89,199,93,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
        this.close();
      }, 1200)
    },
    remind_failure(text) {
      this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
        this.remind_text = '';
      }, 2000)
    },
    In_open(){
      let Email = this.from.Email;
      let password = this.from.password;
      this.$http.get(process.env.VUE_APP_URL + 'admin/login/land', {
        params: {
          Email: Email,
          Paws: password
        }
      }).then(str => {
        if (str.data.sum == '1') {
            let id = str.data.data;
            localStorage.setItem("token", id);
            this.remind_success('登陆成功');
            setTimeout(()=>{
              let goto=this.$route.query.goto;
              if (goto){
                this.$router.push({
                  path: goto
                })
              }else {
                this.$router.push({
                  path: '/pc/home'
                })
              }
            },2000)
          } else {
            this.remind_failure('密码错误')
        }
      })
    },
    Up_open(){
      let goto=this.$route.query.goto;
      if (goto){
        this.$router.push({
          path: '/pc/account/signin',
          query: {
            goto:goto
          }
        })
      }else {
        this.$router.push({
          path: '/pc/home'
        })
      }

    }
  },
  components:{
    Footer
  }
}
</script>

<style scoped lang="scss">
.registered {
  position: relative;
  width: 100%;
  height: 100%;

  .main {
    position: absolute;
    width: 400px;
    height: 500px;
    left: 50%;
    top: 50%;
    margin-top: -250px;
    margin-left: -200px;
    border-radius: 14px;
    border: 1px solid #29294f;
    overflow: hidden;
    .remind {
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .logo {
      background-image: url("../../../assets/image/home/logo.png");
      width: 80px;
      height: 30px;
      background-size: 100% 100%;
      position: absolute;
      left: 30px;
      top: 15px;
    }
    .logo_title{
      position: absolute;
      left: 130px;
      top: 18px;
    }

    .region {
      height: 6px;
      width: 385px;
      position: absolute;
      left: 1px;

      .block {
        background-color: #13ce66;
        position: absolute;
        height: 100%;
        width: 100px;
        left: -100px;
        top: 0;
      }
    }

    @mixin from_block {
      position: absolute;
      width: 360px;
      height: 45px;
      border-radius: 15px;
      border: 1px solid #5f6368;

      .title {
        position: absolute;
        background-color: #ffffff;
        left: 30px;
        top: -12px;
      }

      input {
        position: absolute;
        outline: none;
        border: none;
        width: 339px;
        height: 25px;
        left: 1px;
        border-radius: 15px;
        padding: 10px;
        font-size: 17px;
        transition: 0.2s;

        &:focus {
          left: -1px;
          top: -1px;
          border: 2px solid #29294f;
        }
      }


    }

    .from_Email {
      @include from_block;
      top: 150px;
      left: 20px;
    }
    .from_Password{
      @include from_block;
      top: 260px;
      left: 20px;
    }

    button{
      position: absolute;
      width: 360px;
      height: 40px;
      left: 50%;
      margin-left: -180px;
      bottom: 80px;
      border: none;
      outline: none;
      background: none;
      background-color: #203761;
      color: #ffffff;
      cursor: pointer;
      transition:0.3s;
      border-radius: 10px;
      &:hover{
        background-color: #e4e7ed;
        color: #29294f;
      }
    }
    .footer {
      width: 100%;
      height: 40px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #e4e7ed;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      user-select: none;

      .in_code {
        position: absolute;
        right: 30px;
        color: rgba(15, 69, 121, 0.78);
        top: 7px;
        font-size: 14px;
        cursor: pointer;
      }

      .scan_code {
        position: absolute;
        left: 30px;
        color: rgba(15, 69, 121, 0.78);
        top: 7px;
        font-size: 14px;
        cursor: not-allowed;
      }

      .code_c {
        color: darkred;
        position: absolute;
        left: 100px;
        top: 9px;
        font-size: 8px;
      }

      .scan_account {
        position: absolute;
        left: 110px;
        color: rgba(15, 69, 121, 0.78);
        top: 7px;
        font-size: 14px;
      }
    }
  }
  .Footer {
    position: initial;
    width: 100%;
    height: 40px;
  }
}
</style>
