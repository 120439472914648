<template>
  <div class="footer">
    <div class="nav">
      <div class="name">三驱科技（杭州）有限公司<span></span>Copyright©3QLAB.com 2020<span></span><a
          href="http://beian.miit.gov.cn" target="view_window">浙ICP备19046295号-2</a></div>
      <div class="datatime">Database Update date：February 5, 2020</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@mixin nav_block {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  background-color: #f6f6f6;
}


.footer {
  @include nav_block;
  bottom: 0;
  .nav{
    width:100%;
    height: 100%;
    position: relative;
    .name {
      position: absolute;
      left: 10%;
      font-size: 11px;
      color: #5f6368;
      height: 100%;

      span {
        width: 2rem;
        display: inline-block;
      }

      a {
        color: #5f6368;
        text-decoration: none;
      }
    }

    .datatime {
      position: absolute;
      right: 10%;
      color: #5f6368;
      height: 100%;
      font-size: 12px;
    }
  }



}
</style>